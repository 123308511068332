import { Link } from '@react-navigation/native';
import * as React from 'react';
import { Data } from './data';
import { Container } from './ui';

export function WordsScreen() {
  const word_ids = Object.keys(Data);

  return (
    <Container headingText='Words Screen'>
      {word_ids.map((word_id, i) =>
        <Link to={{ screen: 'WordInfo', params: { word_id: word_id } }}>
          {Data[word_id].name}
        </Link>)
      }
    </Container>
  );
}