import { LinkingOptions, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { Text } from 'react-native';
import { HomeScreen } from './src/home_screen';
import { WordsScreen } from './src/words_screen';
import { WordEntryScreen } from './src/word_entry_screen';
import { WordInfoScreen } from './src/word_info_screen';
import * as Linking from 'expo-linking';

const Stack = createNativeStackNavigator();

export const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: [Linking.createURL('/'), 'https://wordsmeanthings.info'],
  config: {
    screens: {
      Home: '',
      Words: 'words',
      WordEntry: 'word_entry/:word_id',
      WordInfo: 'word_info/:word_id',
    },
  },
};

function App() {
  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <Stack.Navigator initialRouteName="Home">
        <Stack.Screen name="Home" component={HomeScreen} />
        <Stack.Screen name="WordInfo" component={WordInfoScreen} />
        <Stack.Screen name="WordEntry" component={WordEntryScreen} />
        <Stack.Screen name="Words" component={WordsScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default App;
