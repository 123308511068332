import { Link } from '@react-navigation/native';
import * as React from 'react';
import { Data } from './data';
import { Container } from './ui';
import { WordEntry } from './word_entry';
import { WordInfo } from './word_info';

export function HomeScreen() {
  const keys = Object.keys(Data)
  const wordEntryToShowIndex = Math.floor(Math.random() * keys.length);
  const prelimSecondIndex = Math.floor(Math.random() * (keys.length - 1));
  const secondIndex = prelimSecondIndex >= wordEntryToShowIndex ? prelimSecondIndex + 1 : prelimSecondIndex;

  console.log("wordEntryToShowIndex", wordEntryToShowIndex)
  console.log("prelimSecondIndex", prelimSecondIndex)
  console.log("secondIndex", secondIndex)

  const wordEntryToShow = Data[keys[wordEntryToShowIndex]];
  const wordInfoToShow = Data[keys[secondIndex]];

  return (
    <Container headingText='Words Mean Things'>
      <WordInfo wordData={wordInfoToShow} />
      <WordEntry wordData={wordEntryToShow} />
      <Link to={{ screen: 'WordInfo' }}>Go to WordInfo</Link>
      <Link to={{ screen: 'WordEntry' }}>Go to WordEntry</Link>
      <Link to={{ screen: 'Words' }}>Go to Words</Link>
    </Container >
  );
}