import * as React from 'react';
import { Text, View } from 'react-native';
import { WordData } from './data';
import { Container } from './ui';

export function WordEntry(params: { wordData: WordData }) {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Container headingText='WordEntry'>
        <Text>Enter your opinion about {params.wordData.name} here!</Text>
      </Container>
    </View>
  );
}