import * as React from 'react';
import { Text, View } from 'react-native';
import { ValueRange, WordData } from './data';
import { LineChart } from "react-native-chart-kit";
import { Container } from './ui';

export function WordInfo(params: { wordData: WordData }) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)
  const setWindowDimensions = () => { setWindowWidth(window.innerWidth) }
  React.useEffect(() => {
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  var labels = new Array(101);
  var data = new Array(101);
  for (var i = 0; i < 101; i++) {
    labels[i] = i % 10 == 0 ? i : "";
    data[i] = 0;
  }
  params.wordData.submissions.forEach((submission) => {
    if (typeof submission.submissionData == 'number') {
      data[submission.submissionData] += 1;
    } else {
      const minmax = submission.submissionData as ValueRange;
      for (var i = minmax.min; i <= minmax.max; i++) {
        data[i] += 1;
      }
    }
  })

  const heading = `What does ${params.wordData.name} mean?`;

  if (params.wordData.submissions.length < 3) {
    return (
      <View>
        <Container headingText={heading}>
          <Text>We don't have enough data yet</Text>
        </Container>
      </View >
    );
  } else {
    return (
      <View>
        <Container headingText={heading}>
          <LineChart
            data={{
              labels: labels,
              datasets: [
                {
                  data: data
                }
              ]
            }}
            width={windowWidth}
            height={256}
            withDots={false}
            chartConfig={{
              backgroundColor: "#e26a00",
              backgroundGradientFrom: "#fb8c00",
              backgroundGradientTo: "#ffa726",
              decimalPlaces: 0,
              color: (opacity = 1) => `rgba(0,0,0, ${opacity})`,
              labelColor: (opacity = 1) => `rgba(0,0,0, ${opacity})`,
              propsForBackgroundLines: {
                stroke: "none"
              }
            }}
            bezier
          />
        </Container>
      </View >
    );
  }
}
