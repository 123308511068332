export type ValueRange = {
  min: number;
  max: number;
}

type SubmissionData = number | ValueRange;

type Submission = {
  time: Date;
  sourceData: any;
  submissionData: SubmissionData;
};

export type WordData = {
  key: string;
  name: string;
  submissions: Submission[];
};

export var Data: { [base: string]: WordData } = {
  'some': {
    key: 'some',
    name: 'some',
    submissions: [
      {
        time: new Date('2022-10-09T06:42:00'),
        sourceData: {},
        submissionData: { min: 1, max: 99 },
      },
      {
        time: new Date('2022-10-07T06:42:00'),
        sourceData: {},
        submissionData: { min: 1, max: 35 },
      },
      {
        time: new Date('2022-10-04T06:42:00'),
        sourceData: {},
        submissionData: { min: 5, max: 45 },
      },
    ],
  },
  'most': {
    key: 'most',
    name: 'most',
    submissions: [
      {
        time: new Date('2022-10-09T06:42:00'),
        sourceData: {},
        submissionData: { min: 79, max: 100 },
      },
      {
        time: new Date('2022-10-07T06:42:00'),
        sourceData: {},
        submissionData: { min: 60, max: 90 },
      },
      {
        time: new Date('2022-10-04T06:42:00'),
        sourceData: {},
        submissionData: { min: 51, max: 99 },
      },
    ],
  },
  'many': {
    key: 'many',
    name: 'many',
    submissions: [
      {
        time: new Date('2022-10-09T06:42:00'),
        sourceData: {},
        submissionData: { min: 30, max: 90 },
      },
      {
        time: new Date('2022-10-07T06:42:00'),
        sourceData: {},
        submissionData: { min: 40, max: 80 },
      },
      {
        time: new Date('2022-10-04T06:42:00'),
        sourceData: {},
        submissionData: { min: 51, max: 99 },
      },
    ],
  },
  'all': {
    key: 'all',
    name: 'all',
    submissions: [],
  },
  'none': {
    key: 'none',
    name: 'none',
    submissions: [],
  },
  'few': {
    key: 'few',
    name: 'few',
    submissions: [],
  },
};

const words = ['a couple of', 'a great/good deal of', 'a handful of', 'a horde of', 'a large/great number of', 'a lot of', 'a multiplicity of', 'a multitude of', 'a profusion of', 'a slew of', 'a small amount of', 'a small number of', 'a small quantity of', 'a sprinkling of', 'a swag of', 'abundant', 'all', 'an abundance of', 'an army of', 'assorted', 'bags of', 'bazillions of', 'better part', 'billions of', 'copious', 'countless', 'crowds of', 'diverse', 'dozens of', 'droves of', 'eleventy', 'few', 'frequent', 'gazillions of', 'generality', 'gobs of', 'great quantities of', 'greater part', 'hardly any', 'heaps of', 'hundreds of', 'innumerable', 'larger part', 'lion\'s share', 'little', 'loads of', 'lots of', 'main part', 'major part', 'masses of', 'millions of', 'minority', 'more than half', 'more than one can shake a stick at', 'most', 'multifarious', 'multiple', 'multitudinous', 'myriad', 'none', 'not many', 'numberless', 'numerous', 'one or two', 'oodles of', 'piles of', 'plenty of', 'predominance', 'preponderance', 'profuse', 'scads of', 'scarcely any', 'scores of', 'several', 'shedload', 'stacks of', 'sundry', 'the majority', 'thousands of', 'tons of', 'umpteen', 'untold', 'various', 'vulgar slang a shitload of', 'zillions of'];

words.forEach((word) => {
  Data[word] = {
    key: word,
    name: word,
    submissions: [],
  }
});