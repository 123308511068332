import * as React from 'react';
import { View } from 'react-native';
import { Container } from './ui';

export function WordInfoScreen() {
  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Container headingText='WordInfo Screen'>
      </Container>
    </View>
  );
}