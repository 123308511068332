import * as React from 'react';
import { Text, View } from 'react-native';

function Heading(params: { text: string }) {
  return (
    <View style={{ flex: 0.1, alignItems: 'center', justifyContent: 'center' }}>
      <Text style={{ fontSize: 48 }}>{params.text}</Text>
    </View>
  );
}

export function Container(params: { headingText: string, children: JSX.Element | JSX.Element[] }) {
  return (
    <>
      <Heading text={params.headingText} />
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        {params.children}
      </View>
    </>
  );
}